import { FloatingWhatsApp } from 'react-floating-whatsapp';

export default ({ data }) => {
    const {
        phoneNumber = null,
        accountName = null,
        avatar = null,
        statusMessage = null,
        chatMessage = null,
        placeholder = null,
        allowClickAway = true,
        allowEsc = true,
        messageDelay = 1
    } = data || {};

    return (
        <FloatingWhatsApp
            phoneNumber={phoneNumber}
            accountName={accountName}
            avatar={avatar}
            statusMessage={statusMessage}
            chatMessage={chatMessage}
            placeholder={placeholder}
            allowClickAway={allowClickAway}
            allowEsc={allowEsc}
            messageDelay={messageDelay}

        />
    )
}