import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { SectionHeading, Subheading } from "components/misc/Headings.js";
import { PrimaryLink as PrimaryLinkBase } from "components/misc/Links.js";
import { ReactComponent as TimeIcon } from "feather-icons/dist/icons/clock.svg";
import { ReactComponent as LocationIcon } from "feather-icons/dist/icons/map-pin.svg";
import { motion } from "framer-motion";
import { ReactComponent as ArrowRightIcon } from "images/arrow-right-icon.svg";
import { useEffect, useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";

import { css } from "styled-components/macro"; //eslint-disable-line

import * as firestore from '../../services/firestore';

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const ThreeColumn = tw.div`flex flex-wrap`;
const Column = tw.div`xl:mr-12 xl:last:mr-0`;
const HeadingColumn = styled(Column)(props => [
  tw`w-full xl:w-5/12`,
  props.textOnLeft ? tw`xl:order-first` : tw`xl:order-last xl:ml-12 xl:mr-0`
]);
const CardColumn = tw(Column)`w-full md:w-1/2 xl:w-3/12 mt-16 xl:mt-0 xl:last:ml-auto`;

const HeadingInfoContainer = tw.div`text-center xl:text-left max-w-lg xl:max-w-none mx-auto xl:mx-0`;
const HeadingTitle = tw(SectionHeading)`mt-4 xl:text-left leading-tight`;
const HeadingDescription = tw.p`text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100 mt-8`;
const PrimaryLink = styled(PrimaryLinkBase)`
  ${tw`inline-flex justify-center xl:justify-start items-center mt-8 text-lg`}
  svg {
    ${tw`ml-2 w-5 h-5`}
  }
`;

const Card = tw.div`mx-auto xl:mx-0 xl:ml-auto max-w-sm md:max-w-xs lg:max-w-sm xl:max-w-xs`;
//const Card = tw(motion.a)`mx-auto xl:mx-0 xl:ml-auto max-w-sm md:max-w-xs lg:max-w-sm xl:max-w-xs`;
const CardImage = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`h-80 bg-cover bg-center rounded`
]);

const CardText = tw(motion.a)`mt-4`;

const CardHeader = tw.div`flex justify-between items-center`;
const CardCompany = tw.div`text-primary-500 font-bold text-lg`;
const CardType = tw.div`font-semibold text-sm text-gray-600`;

const CardTitle = tw.h5`text-xl mt-4 font-bold`;

const CardMeta = styled.div`
  ${tw`flex flex-row flex-wrap justify-center sm:items-center font-semibold tracking-wide text-gray-600 uppercase text-xs`}
`;

const CardMetaFeature = styled.div`
  ${tw`flex items-center mt-4 mr-4 last:mr-0`}
  svg {
    ${tw`w-5 h-5 mr-1`}
  }
`;
const CardAction = tw(PrimaryButtonBase)`w-full mt-6`;

export default ({ data }) => {

  const [projects, setProjects] = useState([]);
  useEffect(() => {
    async function fetch() {
      if (data) {
        const mainData = await firestore.getEmphasisProjects(data.firstEmphasisProject, data.secondEmphasisProject);
        setProjects(mainData);
      }
    }

    fetch();

  }, [data, setProjects]);

  const {
    subheading = null,
    header = null,
    emphasisHeader = null,
    description = null,
    linkText = null,
    cardLinkText = "Caso de Estudo",
    textOnLeft = false,
  } = data || {};

  const createEmphasisProjects = () => {
    return projects.map((project, index) => (
      <CardColumn key={index}>
        <Card>
          <CardImage imageSrc={project.imageSrc} />
          <CardText href={`/projects/${project.id}`}>
            <CardHeader>
              <CardCompany>{project.company}</CardCompany>
              <CardType>{project.type}</CardType>
            </CardHeader>
            <CardTitle>{project.title}</CardTitle>
            <CardMeta>
              <CardMetaFeature>
                <TimeIcon /> {project.durationText}
              </CardMetaFeature>
              <CardMetaFeature>
                <LocationIcon /> {project.locationText}
              </CardMetaFeature>
            </CardMeta>
            <CardAction>{cardLinkText}</CardAction>
          </CardText>
        </Card>
      </CardColumn>
    ));
  }

  return (
    <Container id='portfolio'>
      <Content>
        <ThreeColumn>
          <HeadingColumn textOnLeft={textOnLeft}>
            <HeadingInfoContainer>
              {subheading && <Subheading>{subheading}</Subheading>}
              <HeadingTitle>
                {header}
                <span tw="text-primary-500">{emphasisHeader}</span>
              </HeadingTitle>
              <HeadingDescription>{description}</HeadingDescription>
              <PrimaryLink href={`/projects`}>
                {linkText} <ArrowRightIcon />
              </PrimaryLink>
            </HeadingInfoContainer>
          </HeadingColumn>
          {createEmphasisProjects()}
        </ThreeColumn>
      </Content>
    </Container>
  );
};
