import { useEffect, useState } from "react";
import tw from "twin.macro";

import { css } from "styled-components/macro"; //eslint-disable-line

import * as firestore from '../../services/firestore';
import SimpleProject from "./SimpleProject";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
const ThreeColumn = tw.div`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20`;

export default () => {

  const [projects, setProjects] = useState([]);
  useEffect(() => {
    async function fetch() {
      const mainData = await firestore.getProjects();
      setProjects(mainData);
    }

    fetch();

  }, [setProjects]);

  const createProjects = (group) => {
    return group.map((project, index) => (
      <SimpleProject project={project} key={index} />
    ));
  }

  const createGroups = () => {
    let groups = [];
    for (let i = 0; i < projects.length; i += 3) {
      groups.push(projects.slice(i, i + 3));
    }

    return groups.map((group, index) => {
      return (
        <ThreeColumn key={index}>
          {createProjects(group)}
        </ThreeColumn>
      )
    });
  }

  return (
    <Container>
      <Content>
        {createGroups()}
      </Content>
    </Container>
  );
};
