import { initializeApp } from "firebase/app";
import { collection, doc, getDoc, getDocs, getFirestore, query } from "firebase/firestore";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export const getData = async (col) => {
  const q = query(collection(db, col));
  const querySnapshot = await getDocs(q);

  const data = {};

  querySnapshot.forEach(async doc => {
    data[doc.id] = doc.data();

  });

  return data;
}

export const getDataAsArray = async (col) => {
  const q = query(collection(db, col));
  const querySnapshot = await getDocs(q);

  const data = [];

  querySnapshot.forEach(async doc => {
    let docData = doc.data();
    docData.id = doc.id;
    data.push(docData);

  });

  return data;
}

export const getMainData = async () => {
  return await getData("home");
}

export const getFeatureCards = async () => {
  return await getDataAsArray("featureCards");
}

export const getMembers = async () => {
  return await getDataAsArray("members");
}

export const getTestimonials = async () => {
  return await getDataAsArray("testimonials");
}

export const getFaqs = async () => {
  return await getDataAsArray("faqs");
}

export const getProjects = async () => {
  return await getDataAsArray("projects");
}

export const getEmphasisProjects = async (firstEmphasisProject, secondEmphasisProject) => {
  const first = await getProject(firstEmphasisProject);
  const second = await getProject(secondEmphasisProject);

  const projects = [first, second];
  return projects;
}

export const getProject = async (ref) => {
  const docRef = doc(db, "projects", ref);
  const docSnap = await getDoc(docRef);
  const data = docSnap.data();
  data.id = ref;
  return data;
}