import { useEffect, useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";

import { css } from "styled-components/macro"; //eslint-disable-line

import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";

import * as firestore from '../../services/firestore';

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const VerticalSpacer = tw.div`mt-10 w-full`

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-sm`}
`;

const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`border text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-2xl leading-none`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-secondary-100 leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

const HighlightedText = tw.span`text-primary-500`;

export default ({ data }) => {

  const [cards, setCards] = useState([]);

  useEffect(() => {
    async function fetch() {
      const featureCards = await firestore.getFeatureCards();
      setCards(featureCards);
    }

    fetch();

  }, [setCards]);

  const {
    header = null,
    emphasisHeader = null,
    subheading = null,
    description = null,
  } = data || {};

  const createCards = () => {
    const result = cards
      .sort((a, b) => a.order - b.order)
      .map((c, i) => {
        return (
          <Column key={c.title}>
            <Card>
              <span className="imageContainer">
                <img src={c.imageSrc} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{c.title && c.title}</span>
                <p className="description">
                  {c.description && c.description}
                </p>
              </span>
            </Card>
          </Column>
        );
      });

    return result;
  }

  return (
    <Container id='services'>
      <ThreeColumnContainer>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>
          {header}
          <HighlightedText>{emphasisHeader}</HighlightedText>
        </Heading>
        {description && <Description>{description}</Description>}
        <VerticalSpacer />
        {createCards()}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
