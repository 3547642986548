
import { SectionHeading } from "components/misc/Headings.js";
import { Container, ContentWithVerticalPadding } from "components/misc/Layouts.js";
import { SectionDescription } from "components/misc/Typography.js";
import { ReactComponent as CheckboxIcon } from "feather-icons/dist/icons/check-circle.svg";
import { ReactComponent as TimeIcon } from "feather-icons/dist/icons/clock.svg";
import { ReactComponent as LocationIcon } from "feather-icons/dist/icons/map-pin.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "images/dot-pattern.svg";
import { useEffect, useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";

import { css } from "styled-components/macro"; //eslint-disable-line

import { useParams } from "react-router-dom";

import ProjectFooter from "components/footers/ProjectFooter";
import ProjectHeader from "components/headers/ProjectHeader.js";
import AnimationRevealPage from "helpers/AnimationRevealPage";
import * as firestore from '../services/firestore';

const Row = tw.div`flex flex-col lg:flex-row justify-between items-center lg:pt-16 max-w-screen-2xl mx-auto sm:px-8`;
const Column = tw.div``;
const TextColumn = tw(Column)`mr-auto lg:mr-0 max-w-lg lg:max-w-xl xl:max-w-2xl`;
const Heading = tw(SectionHeading)`text-left text-primary-500 leading-snug xl:text-6xl`;
const SubHeading = tw(SectionHeading)`text-left text-primary-500 leading-snug xl:text-2xl`;
const Description = tw(SectionDescription)`mt-4 lg:text-base text-gray-700 max-w-lg`;
const FeatureList = tw.ul`mt-12 leading-loose`;
const Feature = tw.li`flex items-center text-primary-500`;
const FeatureIcon = tw(CheckboxIcon)`w-5 h-5 text-primary-500`;
const FeatureText = tw.p`ml-2 font-medium text-gray-700`;
const ImageColumn = tw(Column)`ml-auto lg:mr-0 relative mt-16 lg:mt-0 lg:ml-32`;
const ImageContainer = tw.div`relative z-40 transform xl:-translate-x-24 xl:-translate-y-16`;
const Image = tw.img`max-w-full w-96 rounded-t sm:rounded relative z-20`;
const ImageDecoratorBlob = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none z-10 absolute right-0 bottom-0 transform translate-x-10 translate-y-10 h-32 w-32 opacity-25 text-gray-900 fill-current`}
`;

export default (props) => {

  const [project, setProject] = useState({});

  const { name } = useParams();

  const {
    company = project.company,
    type = project.type,
    useCase = project.useCase,
    imageSrc = project.imageSrc,
    durationText = project.durationText,
    locationText = project.locationText,
    title = project.title,
  } = props.data || {};

  useEffect(() => {
    async function fetch() {
      const mainData = await firestore.getProject(name);
      setProject(mainData);
    }

    fetch();

  }, [setProject, name]);

  return (
    <>
      <AnimationRevealPage>
        <ProjectHeader />
        <Container>
          <ContentWithVerticalPadding>
            <Row>
              <TextColumn>
                <Heading>{company}</Heading>
                <SubHeading>{title}</SubHeading>
                <Description>{useCase}</Description>
                <FeatureList>
                  <Feature key={1}>
                    <TimeIcon />
                    <FeatureText>{durationText}</FeatureText>
                  </Feature>
                  <Feature key={2}>
                    <FeatureIcon />
                    <FeatureText>{type}</FeatureText>
                  </Feature>
                  <Feature key={3}>
                    <LocationIcon />
                    <FeatureText>{locationText}</FeatureText>
                  </Feature>
                </FeatureList>
              </TextColumn>
              <ImageColumn>
                <ImageContainer>
                  <Image src={imageSrc} />
                  <ImageDecoratorBlob />
                </ImageContainer>
              </ImageColumn>
            </Row>
          </ContentWithVerticalPadding>
        </Container>
        <ProjectFooter />
      </AnimationRevealPage>
    </>
  );
};