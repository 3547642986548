
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

import ProjectFooter from "components/footers/ProjectFooter";
import ProjectHeader from "components/headers/ProjectHeader.js";
import ProjectsList from "components/portfolio/ProjectsList";

export default () => {

  return (
    <AnimationRevealPage>
      <ProjectHeader />
      <ProjectsList />
      <ProjectFooter />
    </AnimationRevealPage>
  );
}
