import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Projects from "scenes/FullPortfolio";
import MainLandingPage from "scenes/LandingPage.js";
import Project from "scenes/Project";
import GlobalStyles from 'styles/GlobalStyles';

export default function App() {
  return (
    <>
      <GlobalStyles />
      <Router>
        <Routes>
          <Route path="/projects" element={<Projects />} />
          <Route path="/projects/:name" element={<Project />} />
          <Route path="/" element={<MainLandingPage />} />
        </Routes>
      </Router>
    </>
  );
}