import { useEffect, useState } from "react";

import { css } from "styled-components/macro"; //eslint-disable-line

import FAQs from "components/faqs/Faqs.js";
import Features from "components/features/Features.js";
import MainFeature from "components/features/MainFeature.js";
import Footer from "components/footers/Footer.js";
import Contact from "components/forms/Contact.js";
import Banner from "components/features/Banner.js";
import Members from "components/members/Members.js";
import Portfolio from "components/portfolio/Portfolio.js";
import Testimonials from "components/testimonials/Testimonials.js";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Whatsapp from "widget/Whatsapp";

import * as firestore from '../services/firestore';

export default () => {

  const [mainData, setMainData] = useState({});

  useEffect(() => {
    async function fetch() {
      const mainData = await firestore.getMainData();
      setMainData(mainData);
    }

    fetch();

  }, [setMainData]);

  return (
    <AnimationRevealPage>
      <Whatsapp data={mainData.whatsapp} />
      <Banner data={mainData.main} />
      <MainFeature data={mainData.mainFeature} />
      <Features data={mainData.features} />
      <Members data={mainData.members} />
      <Portfolio data={mainData.portfolio} />
      <Testimonials data={mainData.testimonials} />
      <FAQs data={mainData.faqs} />
      <Contact data={mainData.contact} />
      <Footer />
    </AnimationRevealPage>
  );
}
