import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { SectionHeading as HeadingTitle, Subheading } from "components/misc/Headings.js";
import { ReactComponent as TagIcon } from "feather-icons/dist/icons/tag.svg";
import { ReactComponent as UserIcon } from "feather-icons/dist/icons/user.svg";
import { useEffect, useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../../images/svg-decorator-blob-3.svg";

import { css } from "styled-components/macro"; //eslint-disable-line

import * as firestore from '../../services/firestore';

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
const MultipleColumn = tw.div`flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap`;
const Column2 = tw.div`mt-12 lg:w-1/2`;
const Column3 = tw.div`mt-12 lg:w-1/3`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const Card = tw.div`relative lg:w-8/12 mx-auto lg:max-w-none`;
const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`bg-cover bg-center h-80 lg:h-64 rounded rounded-b-none`,
  tw`bg-contain bg-no-repeat`
]);

const Details = tw.div`p-6 rounded rounded-t-none flex-1 flex flex-col items-center text-center lg:block lg:text-left`;
const MetaContainer = tw.div`flex items-center`;
const Meta = styled.div`
  ${tw`text-secondary-100 font-medium text-sm flex items-center leading-none mr-6 last:mr-0`}
  svg {
    ${tw`w-4 h-4 mr-1`}
  }
`;

const Title = tw.h5`mt-4 leading-snug font-bold text-lg`;
const Description = tw.p`mt-2 text-sm text-secondary-100`;
const Link = styled(PrimaryButtonBase).attrs({ as: "a" })`
  ${tw`inline-block mt-4 text-sm font-semibold`}
`

const DecoratorBlob1 = tw(
  SvgDecoratorBlob1
)`-z-10 absolute bottom-0 right-0 w-48 h-48 transform translate-x-40 -translate-y-8 opacity-25`;
const DecoratorBlob2 = tw(
  SvgDecoratorBlob2
)`-z-10 absolute top-0 left-0 w-48 h-48 transform -translate-x-32 translate-y-full opacity-25`;

export default ({ data }) => {

  const [members, setMembers] = useState([]);
  useEffect(() => {
    async function fetch() {
      const mainData = await firestore.getMembers();
      setMembers(mainData);
    }

    fetch();

  }, [setMembers]);

  const {
    subheading = null,
    header = null,
    emphasisHeader = null,
    description = null,
  } = data || {};

  const createMembers = () => {

    const Column = members.length > 2 ? Column3 : Column2;

    return members
      .sort((a, b) => a.order - b.order)
      .map((member, index) => (
        <Column key={index}>
          <Card>
            <Image imageSrc={member.imageSrc} />
            <Details>
              <MetaContainer>
                <Meta>
                  <UserIcon />
                  <div>{member.name}</div>
                </Meta>
                {member.position &&
                  <Meta>
                    <TagIcon />
                    <div>{member.position}</div>
                  </Meta>
                }
              </MetaContainer>
              <Title>{member.title}</Title>
              <Description>{member.description}</Description>
              {(member.buttonText && member.link) && <Link href={member.link}>{member.buttonText}</Link>}
            </Details>
          </Card>
        </Column>
      ));
  }

  return (
    <Container id='members'>
      <Content>
        <HeadingInfoContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <HeadingTitle>
            {header}
            <span tw="text-primary-500">{emphasisHeader}</span>
          </HeadingTitle>
          <HeadingDescription>{description}</HeadingDescription>
        </HeadingInfoContainer>
        <MultipleColumn>
          {createMembers()}
        </MultipleColumn>
      </Content>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};
